import { Component, OnInit, ViewChild,ContentChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SearchService } from '../shared/services/entitySearch/search.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { PopupComponent } from '../shared/popup/popup.component';
import { Subscription } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PageEvent, MatPaginator} from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { SearchinfoPopupComponent } from '../shared/searchinfo-popup/searchinfo-popup.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntitySearchComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  @ViewChild('org', {static: false}) oPaginator : MatPaginator;
  @ViewChild('quote', {static: false}) qPaginator : MatPaginator;
  @ViewChild('ins', {static: false}) iPaginator : MatPaginator;
 
  // @ViewChild ('f') searchForm: NgForm;
  //@ViewChild('org') oPaginator: MatPaginator;
  //@ViewChild('quote') qPaginator: MatPaginator;
  //@ViewChild('ins') iPaginator: MatPaginator;
  searchedKey:string = '';
  searchResultsText: string = '';
  isLoading = false;
  result:any;
  getOrganizationsData:any;
  datasortingValue: any;
  config: any;
  pageSizeOption = [5, 10];
  orgPageSize = 5;
  instrumentPageSize = 5;
  quotePageSize = 5;
  noDataFound = false;
  entityTypeResults: any;

  totalItems:number;
  selectedData:any = '0';
  sortingList = [
    {id: "0", name: 'Relevance'},
    {id: "1", name: 'Asc'},
    {id: "2", name:'Desc'}
  ];

  constructor(private http: HttpClient,
    private searchService: SearchService,
    private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private title: Title) {
    this.config = {
      num: 5,
      start: 1,
      q:'',
      entityType: null,
      selected:'all'
    }

    this.entityTypeResults = {
      organizations: null,
      instruments: null,
      quotes: null
    }


  }

  ngOnInit() {
    this.title.setTitle('Search' + ' | PermID');
    this.route.params.subscribe((params: Params) => {
      // console.log(params);
    this.config.q = params['search'] || ''
    this.searchedKey = this.config.q;
    if(this.searchedKey) {
      this.config.start = 1;
      this.config.num = 5;
      this.config.selected = "all"
      this.orgPageSize = 5;
      this.instrumentPageSize = 5;
      this.quotePageSize = 5;
      this.config.entityType = null;
      this.searchResultsText = this.searchedKey;
      this.isLoading = true;
      this.getEntityResults(this.config);
    }
    this.selectedData = this.sortingList[0];
    
   });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }
  DescSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (b[property] < a[property]) ? -1 : (b[property] > a[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }
  sortRevelance(){
    this.route.params.subscribe((params: Params) => {
    this.config.q = params['search'] || ''
    this.searchedKey = this.config.q;
    if(this.searchedKey) {
      this.config.start = 1;
      this.config.num = 5;
      this.config.selected = "all"
      this.orgPageSize = 5;
      this.instrumentPageSize = 5;
      this.quotePageSize = 5;
      this.config.entityType = null;
      this.searchResultsText = this.searchedKey;
      this.isLoading = true;
      this.getEntityResults(this.config);
    }
  });
  }

 sortingValue(){
   // let data1 = this.entityTypeResults.organizations.entities.some(obj => 'hasHoldingClassification' in obj && Object.keys('hasHoldingClassification').length > 0);
  // let data2 = this.entityTypeResults.organizations.entities.some(obj => 'hasURL' in obj && Object.keys('hasURL').length > 0);   
   if(this.selectedData.name == "Asc" ){
     this.entityTypeResults.organizations.entities.sort(this.dynamicSort("organizationName"));
      this.entityTypeResults.instruments.entities.sort(this.dynamicSort("hasName"));
      this.entityTypeResults.quotes.entities.sort(this.dynamicSort("hasName"));     
    }
    else if(this.selectedData.name =="Desc"){
      this.entityTypeResults.organizations.entities.sort(this.DescSort("organizationName"));
      this.entityTypeResults.instruments.entities.sort(this.DescSort("hasName"));
      this.entityTypeResults.quotes.entities.sort(this.DescSort("hasName"));
    } else {
      this.ngOnInit();
    }
  }

  // onSubmit(event, searchKey) {
  //   event.preventDefault();
  //   this.config.q = searchKey.search;
  //   this.searchResultsText = this.config.q;
  //   this.isLoading = true;
  //   this.getEntityResults(this.config);
  // }

  getEntityResults(filters) {
      this.noDataFound = false;
      this.isLoading = true;
      if(filters.q == 'tesla'){
        filters.q = 'tesla inc';
      }
      if(filters.q == 'tesla' || filters.q == 'tesla inc' || filters.q == 'tesla '){
        filters.num = 20;
      this.subscription = this.searchService.getSearchResults(filters).subscribe(responseData => {
      const entityTypes = ["organizations", "instruments", "quotes"];
      let totalItem = 0;
      this.isLoading = false;
      this.result = responseData;
      this.result = this.result.result;
      entityTypes.forEach(entityType=> {
        if(this.result[entityType]) {
          this.entityTypeResults[entityType] = this.result[entityType];
          totalItem += this.result[entityType].total;
        }
      })
    let dataVal = this.entityTypeResults.organizations.entities;
    dataVal?.map((item:any,index)=>{
      if(("hasHoldingClassification" in item) && ("hasURL" in item) && ("primaryTicker" in item)){
        this.datasortingValue = item
      }
    })
    if((this.datasortingValue !=null || this.datasortingValue !=undefined || this.datasortingValue !="") && (filters.pageIndex == 0 || filters.pageIndex == undefined)){
      this.entityTypeResults.organizations.entities.unshift(this.datasortingValue);
    } else{
      this.entityTypeResults.organizations.entities;
    }
    //Remove duplicates from json array ... 
    if(this.entityTypeResults.organizations.entities[0] == null){
      this.entityTypeResults.organizations.entities.shift();
    }
    this.entityTypeResults.organizations.entities = this.uniqByKeepFirst(this.entityTypeResults.organizations.entities, it => it.organizationName);   
    //console.log("filters...."+JSON.stringify(filters))
    if(filters.pageSize == 5 || filters.pageSize == undefined || filters.entityType == null){
      this.entityTypeResults.organizations.entities = this.entityTypeResults.organizations.entities.slice(0, 5);
      this.entityTypeResults.instruments.entities = this.entityTypeResults.instruments.entities.slice(0,5);
      this.entityTypeResults.quotes.entities = this.entityTypeResults.quotes.entities.slice(0,5);
    } else{
      this.entityTypeResults.organizations.entities = this.entityTypeResults.organizations.entities.slice(0, 10);
      this.entityTypeResults.instruments.entities = this.entityTypeResults.instruments.entities.slice(0,10);
      this.entityTypeResults.quotes.entities = this.entityTypeResults.quotes.entities.slice(0,10);
    }
    this.totalItems = totalItem;
      if(!this.totalItems) {
        this.noDataFound = true;
      }
      // this.noDataFound = false;
    }, error => {
      this.isLoading = false;
      // console.log(error.message);
      this.router.navigate(['/']);
    }) 
  } else{
    this.subscription = this.searchService.getSearchResults(filters).subscribe(responseData => {
      const entityTypes = ["organizations", "instruments", "quotes"];
      let totalItem = 0;
      this.isLoading = false;
      this.result = responseData;
      this.result = this.result.result;
      entityTypes.forEach(entityType=> {
        if(this.result[entityType]) {
          this.entityTypeResults[entityType] = this.result[entityType];
          totalItem += this.result[entityType].total;
        }
      })    
      this.totalItems = totalItem;
      if(!this.totalItems) {
        this.noDataFound = true;
      }
      // this.noDataFound = false;
    }, error => {
      this.isLoading = false;
      // console.log(error.message);
      this.router.navigate(['/']);
    }) 
  }

  }

  uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
  }

  openPopup() {
    this.dialog.open(SearchinfoPopupComponent, {
      width: '600px'
    });
  }
  onChangedPage(page: PageEvent, type) {
    //console.log("page"+JSON.stringify(page));
    let currentPage = page.pageIndex + 1;
    this.config.num = page.pageSize;
    this.orgPageSize = page.pageSize;
    this.config.pageSize = page.pageSize;
    this.config.pageIndex = page.pageIndex;
    this.config.start = (this.config.num * (currentPage - 1)) + 1;
    // this.config.selected = 'all';
    this.config.entityType = type;
    if(this.config.selected === type) {
      this.config.selected = type
    }
    this.getEntityResults(this.config);
  }

  onTabChanged(type) {
    this.config.entityType = type;
    this.config.selected = type;
    this.config.num = 10;
    this.config.start = 1;
    // this.oPaginator.pageIndex = 0;
    // this.iPaginator.pageIndex = 0;
    // this.qPaginator.pageIndex = 0;

    if(this.config.selected === 'all') {
      this.config.entityType = null;
      this.config.num = 5;
      this.orgPageSize = 5;
      this.instrumentPageSize = 5;
      this.quotePageSize = 5;
    } else {
      this.orgPageSize = 10;
      this.instrumentPageSize = 10;
      this.quotePageSize = 10;
    }


    this.getEntityResults(this.config);
  }

  // loadPage(pageNumber: number, type:string) {
  //   let start = pageNumber || 1;
  //   start = (start === 1)? 1 : ((start -1) * this.config.num) + 1;
  //   this.config.start = start;
  //   this.isLoading = true;
  //   // this.config.selected = type;
  //   // this.config.entityType = type;
  //   console.log("Config pagination",this.config)
  //   this.getEntityResults(this.config);
  // }

  refreshPage() {
    window.location.reload();
  }

  ngOnDestroy() {
    if(this.subscription && !this.subscription.closed) {
       this.subscription.unsubscribe();
    }
  }

}