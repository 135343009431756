// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //URL of development API
  //apiUrl : 'http://10.184.75.38:8000/api',
  //apiUrl: 'http://10.185.26.32:8000/api',
  domain:'https://permid.org',
  registerUrl: "https://my.uat.refinitiv.com/content/mytr/en/register.html",
  updateProfileUrl:"https://my.uat.refinitiv.com/content/mytr/en/userprofile.html?&truid=##truid##",
  // apiUrl: 'http://10.184.75.38:8000/api'
  // apiUrl: 'http://t5bvdiw10dv0482.ten.thomsonreuters.com:15151/api'
  // apiUrl: 'http://10.184.75.41:8000/api'
 // apiUrl: 'http://10.185.26.32:8000/api'
  // apiUrl: 'http://10.185.16.71:8000/api'
  apiUrl : 'https://permid.org/api',
  accesstokenQA : 'f1lG9qIPFRZdo1TNbqfMoywaIiF9mUYp',
  accesstokenPPE : 'vlrruAeumozYEEcgWMlC8C0ADZj5wq8Q',
  accesstokenPROD : 'f1lG9qIPFRZdo1TNbqfMoywaIiF9mUYp'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
